import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import type { SanityTextColor } from "../types";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "../../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";
import spaces from "../../../styles/theme/spaces";
import { gradientImageOverlay } from "../../../styled/mixins";
import text from "./RegularBanner.typography";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type TextWrapperProps = {
  textPosition?: string;
  textColor: SanityTextColor;
  hasDisclaimerInformation: boolean;
};

const disclaimerInformationIconSize = {
  mobile: "18px",
  laptop: "24px",
} as const;

export const TextWrapper = styled.div<TextWrapperProps>(
  ({ textColor, textPosition, hasDisclaimerInformation }) => css`
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: ${textColor};
    justify-content: ${textPosition === "bottom" ? "flex-end" : "flex-start"};
    padding: ${hasDisclaimerInformation
      ? `calc(${spaces.regular} + ${disclaimerInformationIconSize.mobile} + ${spaces.micro}) ${spaces.smallRegular} 0`
      : `${spaces.regular} ${spaces.smallRegular} 0`};

    ${MQ("laptop")} {
      padding: ${hasDisclaimerInformation
        ? `calc(${spaces.large} + ${disclaimerInformationIconSize.laptop} + ${spaces.micro}) ${spaces.large} ${spaces.large}`
        : `${spaces.large}`};
    }
  `
);

type BannerTypeProps = {
  isExtraSmall?: boolean;
  textColor?: string;
};
export const Label = styled.span<BannerTypeProps>(
  ({ isExtraSmall = false, textColor }) => css`
    ${typographyToCss(text.bannerLabel)}
    margin-bottom: ${spaces.micro};
    ${isExtraSmall && typographyToCss(text.extraSmallLabel)}

    ${isNotNullOrUndefined(textColor) &&
    css`
      color: ${textColor};
    `}
  `
);

export const Headline = styled.h3<BannerTypeProps>(
  ({ isExtraSmall = false, textColor }) => css`
    ${typographyToCss(text.bannerHeadline)}
    margin: 0 0  ${spaces.micro};
    ${isExtraSmall && typographyToCss(text.extraSmallHeadline)}

    ${isNotNullOrUndefined(textColor) &&
    css`
      color: ${textColor};
    `}
  `
);

export const Description = styled.span<BannerTypeProps>(
  ({ isExtraSmall = false, textColor }) => css`
    ${typographyToCss(text.bannerDescription)}
    ${isExtraSmall && typographyToCss(text.extraSmallDescription)}

    ${isNotNullOrUndefined(textColor) &&
    css`
      color: ${textColor};
    `}
  `
);

export const Button = styled.button`
  margin: ${spaces.smallRegular};
  position: relative;
  z-index: 1;

  ${MQ("laptop")} {
    margin: ${spaces.large};
  }
`;

type TransparentButtonProps = {
  textColor: SanityTextColor;
};
export const TransparentButtonWithIcon = styled(Button)<TransparentButtonProps>(
  ({ textColor }) => css`
    ${typographyToCss(text.buttonWithIcon)}
    display: flex;
    align-items: baseline;
    background-color: transparent;
    border: none;
    color: ${textColor};
    padding: 0;
    height: 11px;
    :after {
      width: 6px;
      height: 6px;
      ${MQ("tabletHorizontal")} {
        width: 8px;
        height: 8px;
      }

      margin-left: 5px;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: right 0.2s ease;
      content: "";
    }
  `
);

type BackgroundWrapperProps = {
  hasImageOverlay: boolean;
  imageUrl?: string;
  backgroundColor?: string;
  isBannerALink?: boolean;
};
export const BackgroundWrapper = styled.div<BackgroundWrapperProps>(
  ({ backgroundColor, hasImageOverlay, isBannerALink = false }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    ${isBannerALink && `cursor: pointer;`}
    background-position: center;
    background-color: ${backgroundColor ?? colors.xxlOrange};
    ${hasImageOverlay && gradientImageOverlay()}
    position: relative;
    ${isBannerALink &&
    interactionFeedback(css`
      cursor: pointer;
      & ${TransparentButtonWithIcon} {
        cursor: pointer;
        text-decoration: underline;
      }
    `)}
  `
);
