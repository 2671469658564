/**
 * Copied from the next-js-app.
 * Used while next and legacy versions of the Homepage is active.
 * Ref: XD-13282
 */

import { isNotNullOrUndefined } from "@xxl/common-utils";
import { IMAGE_BASE_SOURCE_URL } from "react-app/src/constants";

const IMAGE_QUALITY_DEFAULT = 75;
const IMAGE_PADDING_DEFAULT = "0";
const BACKGROUND_COLOR = "#efefef";

type HotSpot = { x: number; y: number } | undefined;
type ImageLoader = {
  src: string;
  width: number;
  backgroundColor?: `#${string}` | "transparent";
  quality?: number;
};

const sanitizeBaseUrl = (s: string) => s.split("?")[0]; // Removes any query params from the baseUrl before adding the new params
const isFilespinImageUrl = (imageSrc: string) =>
  imageSrc.includes(IMAGE_BASE_SOURCE_URL.filespin);
const isSanityImageUrl = (imageSrc: string) =>
  imageSrc.includes(IMAGE_BASE_SOURCE_URL.sanity);
const hasFocalpoint = (hotspot: HotSpot) =>
  isNotNullOrUndefined(hotspot) &&
  isNotNullOrUndefined(hotspot.x) &&
  isNotNullOrUndefined(hotspot.y);

const addFocalpointParameters = (
  focalPoints: { x: number; y: number },
  params: URLSearchParams
): void => {
  const focalParams = {
    fit: "crop",
    crop: "focalpoint",
    "fp-x": focalPoints.x.toString(),
    "fp-y": focalPoints.y.toString(),
  };

  Object.entries(focalParams).forEach(([key, value]) => params.set(key, value));
};

export const imageLoader =
  (hotspot: HotSpot, padding?: number) =>
  ({
    backgroundColor = BACKGROUND_COLOR,
    quality = IMAGE_QUALITY_DEFAULT,
    src,
    width,
  }: ImageLoader) => {
    const qualityParam = quality.toString();
    const params = new URLSearchParams({
      quality: qualityParam,
      ...(backgroundColor !== "transparent" && {
        bgcolor: backgroundColor.replace("#", ""),
      }),
      pad: padding !== undefined ? padding.toString() : IMAGE_PADDING_DEFAULT,
    });

    if (isSanityImageUrl(src)) {
      params.set("auto", "format");
      params.set("w", width.toString());

      // Only FileSpin uses quality, Sanity uses q
      params.delete("quality");
      params.set("q", qualityParam);
    }
    if (isFilespinImageUrl(src)) {
      params.set("resize", `${width},${width}`);
    }

    if (params.get("pad") === IMAGE_PADDING_DEFAULT) {
      params.delete("pad");
    }

    if (hasFocalpoint(hotspot)) {
      addFocalpointParameters(hotspot as { x: number; y: number }, params);
    }

    return `${sanitizeBaseUrl(src)}?${params.toString()}`;
  };
