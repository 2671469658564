import { isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  CountdownTimerSettings,
  Image as IconData,
  Product,
} from "@xxl/content-api";
import { default as React } from "react";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { handleImageError } from "../../../utils/xxl-image";
import { CountdownTimer } from "../../CountdownTimer";
import {
  OtherPrice,
  OtherPriceDisclaimer,
  OtherPriceWrapper,
  SalesPrice,
} from "../../PriceDisplays/PriceDisplays.styled";
import type { SmallProductBannerPricing } from "../../PriceDisplays/price-display-helper";
import { BannerSize } from "../../PriceDisplays/price-display-helper";
import { CountdownTimeContainer } from "../Banner.styled";
import {
  Headline,
  Label,
  ProductName,
  Subtitle,
} from "../HighlightedProductBanner/HighlightedProductBanner.styled";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  ProductBannerImageSettings,
  getFetchPriority,
  getImageProp,
} from "../Shared/BannerContent/BannerContent.helper";
import type { SanityTextColor } from "../types";
import {
  BackgroundWrapper,
  Button,
  CheapestPrice,
  Icon,
  Image,
  PriceWrapper,
  ProductBrand,
  ProductDescription,
  ProductInfoWrapper,
  RibbonWrapper,
} from "./ProductBanner.styled";
import { Ribbon } from "./Ribbon/Ribbon";

type ProductBannerProps = {
  positionInHomepage: number;
  priceData: SmallProductBannerPricing;
  size: BannerSize;
  textColor: SanityTextColor;
  backgroundColor?: string;
  buttonLabel?: string;
  cheapestPrice?: string;
  countdownTimerSettings?: CountdownTimerSettings | null;
  icon?: IconData;
  product?: Product;
  productBrand?: string;
  productDescription?: string;
  productName?: string;
  headline?: string;
  label?: string;
  subtitle?: string;
};

const ProductBanner = ({
  backgroundColor,
  buttonLabel,
  cheapestPrice,
  countdownTimerSettings,
  icon,
  positionInHomepage,
  priceData,
  product,
  productBrand,
  productDescription,
  productName,
  size,
  textColor,
  headline,
  label,
  subtitle,
}: ProductBannerProps) => {
  const { prices, ribbon } = priceData;
  const { alternate, selling, type: priceType } = prices;
  const isDiscountedMainPrice =
    isNotNullOrUndefined(alternate) && isNotNullOrUndefined(selling)
      ? selling.priceUnformatted < alternate.priceUnformatted
      : false;

  const isTextBlack = textColor === "black";
  const isSmallBanner = size !== BannerSize.MEDIUM;

  const fetchPriority = getFetchPriority(positionInHomepage);
  const isMobile = useXxlMediaQuery("MobileMediaQuery");

  const imageSettings: ImageSettings = {
    fetchPriority,
    isMobile,
    sizes: ProductBannerImageSettings[size].sizes,
    srcSetSizes: ProductBannerImageSettings[size].srcSetSizes,
  };

  const image = isNotNullOrUndefined(product)
    ? {
        url: product.imageUrl,
        alt: productName,
      }
    : undefined;

  const backgroundImage = isNotNullOrUndefined(image)
    ? getImageProp(imageSettings, image)
    : undefined;

  return (
    <BackgroundWrapper
      backgroundColor={backgroundColor}
      bannerSize={size}
      data-testid="product-banner"
    >
      {isNotNullOrUndefined(ribbon) && (
        <RibbonWrapper bannerSize={size}>
          <Ribbon
            bannerSize={size}
            colors={ribbon.colors}
            label={ribbon.label}
          />
        </RibbonWrapper>
      )}

      <ProductInfoWrapper textColor={textColor}>
        {isNotNullOrUndefined(label) && <Label>{label}</Label>}
        {isNotNullOrUndefined(headline) && (
          <Headline bannerSize={size}>{headline}</Headline>
        )}
        {isNotNullOrUndefined(subtitle) && <Subtitle>{subtitle}</Subtitle>}
        {productBrand !== undefined && (
          <ProductBrand bannerSize={size}>{productBrand}</ProductBrand>
        )}
        {isNotNullOrUndefined(productName) && (
          <ProductName bannerSize={size}>{productName}</ProductName>
        )}
        {productDescription !== undefined && (
          <ProductDescription>{productDescription}</ProductDescription>
        )}
        {countdownTimerSettings !== null &&
          countdownTimerSettings?.date !== undefined && (
            <CountdownTimeContainer>
              <CountdownTimer
                date={new Date(countdownTimerSettings.date)}
                isBlackText={countdownTimerSettings.isBlack}
                isOnlyHours={countdownTimerSettings.isOnlyHours}
                description={countdownTimerSettings.description}
              />
            </CountdownTimeContainer>
          )}
      </ProductInfoWrapper>
      {isNotNullOrUndefined(prices) && (
        <PriceWrapper textColor={textColor}>
          <SalesPrice
            bannerSize={size}
            isDiscountedMainPrice={isDiscountedMainPrice}
          >
            {prices.selling.price}
          </SalesPrice>
          {isNotNullOrUndefined(alternate) && (
            <OtherPriceWrapper isSmall={isSmallBanner}>
              <OtherPrice
                isInvertedPrice={
                  priceType === "REWARD" ? true : !isDiscountedMainPrice
                }
              >
                {alternate.price}
              </OtherPrice>
              &nbsp;
              <OtherPriceDisclaimer>{alternate.label}</OtherPriceDisclaimer>
            </OtherPriceWrapper>
          )}
          {isNotNullOrUndefined(cheapestPrice) && (
            <CheapestPrice>{cheapestPrice}</CheapestPrice>
          )}
        </PriceWrapper>
      )}
      {buttonLabel !== undefined && (
        <Button
          className={`button button--small button--${
            isTextBlack ? "primary" : "secondary"
          }`}
        >
          {buttonLabel}
        </Button>
      )}
      {backgroundImage !== undefined && (
        <Image
          data-private={true}
          bannerSize={size}
          className="product-banner-image"
          sizes={backgroundImage.sizes}
          src={backgroundImage.url}
          srcSet={backgroundImage.srcSet}
          alt={backgroundImage.alt}
          fetchPriority={backgroundImage.fetchPriority}
          onError={handleImageError}
        />
      )}
      {isNotNullOrUndefined(icon?.url) && (
        <Icon src={icon.url} alt={icon.alt} bannerSize={size} />
      )}
    </BackgroundWrapper>
  );
};

export default ProductBanner;
